
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import UIButton from "@/components/UI/Button/UIButton.vue";
import WSATextField from "@/components/UI/TextFields/WsaTextField.vue"
import UITitle from "@/components/UI/Text/UITitle.vue";
import UISubtitle from "@/components/UI/Text/UISubtitle.vue";
import Vue from "vue";
import User from "@/models/User";
import WsaButton from "../UI/Button/wsa-button.vue";
import PartnerSettings from "@/models/PartnerSettings";
import FirebaseAnalyticsScreensNames from "@/firebase/firebaseAnalyticsScreensNames";
import AddressPosition from "@/models/AddressPosition";
import phoneNumberMixin from "@/mixins/phoneNumberMixin"
import MainCard from "@/components/MainDialogComponents/MainDialogCard.vue"
import PartnerLogo from "../PartnerLogo/PartnerLogo.vue";
import _requestHelper, { _axios } from "@/apiManager/_requestHelper";
import { Apis } from "@/apiManager/Apis";
import CloseIcon from "../UI/Icons/CloseIcon.vue";
import WsaPhoneNumber from "../UI/TextFields/WsaPhoneNumber.vue";
import Recaptcha from "../Recaptcha.vue";

export default Vue.extend({

  props: ["redirectTo", "isDialogOpen"],

  components: {
    UIButton,
    "wsa-text-field": WSATextField,
    "ui-title": UITitle,
    "ui-subtitle": UISubtitle,
    "wsa-button": WsaButton,
    "main-card": MainCard,
    "partner-logo": PartnerLogo,
    CloseIcon,
    "wsa-phone-number": WsaPhoneNumber,
    "re-captcha": Recaptcha
  },

  mixins: [phoneNumberMixin],

  data: () => ({
    isPasswordVisisble: false,
    credentials: {
      phoneNumber: "" as string,
      password: "" as string,
      tempPhoneNumber: "" as string,
      countryCode: "" as any
    } as any,

    rule: [
      (v: any) =>
        (typeof v != "undefined" && v != "") || "This field is required",
    ],

    passwordRule: [
      (v: any) =>
        (typeof v != "undefined" && v != "" && v.length > 7) ||
        "This field is required",
    ],

    isPhoneNumberValid: false as boolean,
    loading: false as boolean,
    fullLoading: false as boolean,
    loginAttempts: 0 as number,
    isRecaptchaVerified: false as boolean,
    resetRecaptcha: false as boolean
  }),

  computed: {
    ...mapState("loading", ["authenticationLoading"]),
    ...mapState("partner", ["partnerSettings", "appDataContent"]),
    ...mapState("store", ["selectedStoreDetails", "listOfStores"]),
    ...mapState("maps", ["address"]),
    ...mapState("address", ["listOfUserAddresses"]),
    ...mapState("auth", ["isFirstTimeOpened"]),
    
    ...mapGetters("auth", ["currentSelectedUser"]),

    userObject() : User{
      return this.currentSelectedUser;
    },

    isReceptchaActive(): boolean {
      return this.loginAttempts >= 3
    }
    
  },

  methods: {
    ...mapActions("auth", ["authenticate"]),
    ...mapActions("store", ["getStoreDetails"]),
    ...mapActions("maps", ["getAddress"]),
    ...mapActions("address", ["createAddress","updateAddress", "getUserAddresses"]),

    ...mapMutations("auth", ["ADD_LOCAL_ADDRESS", "SET_USER_USED_ADDRESS", "SET_FIRST_TIME_OPENNED_VALUE"]),
    ...mapMutations("firebase", ["LOG_FIREBASE_SCREEN"]),


  
    validate(): boolean {
      return (this.$refs.loginForm as any).validate();
    },

    createOrUpdateAddress(adrId: number = 0) {
      var adrObj = {   
        id: adrId,
        tag: this.userObject.usedAddress?.tag.id,
        tagDescription: this.userObject.usedAddress?.tagDescription,
        streetNumber: this.userObject.usedAddress?.streetNbre,
        streetName: this.userObject.usedAddress?.streetName,
        postalCode: this.userObject.usedAddress?.postalcode,
        city: this.userObject.usedAddress?.city,
        area: this.userObject.usedAddress?.area,
        state: this.userObject.usedAddress?.state,
        country: this.userObject.usedAddress?.country,
        countryCode: this.userObject.usedAddress?.countryCode,
        additionalAddressInformation: this.userObject.usedAddress?.additionalAddressInfo,
        coordinatesLongitude: this.userObject.usedAddress?.position.longitude,
        coordinatesLatitude: this.userObject.usedAddress?.position.latitude,
        IsMultiAddressSA: true
      } as any;

      if(adrId > 0) {        
        this.updateAddress({ address: adrObj })
        .then(() => {
          this.getUserAddresses()
          .then(() => {
            var primaryAddress = this.userObject.deliveryAddressesFromServer.find(adr => adr.isPrimary == true);
            this.SET_USER_USED_ADDRESS(primaryAddress)
          });
        });
      }

      else {
        this.createAddress({ address: adrObj })
        .then(() => {
            this.getUserAddresses()
            .then(() => {
              var primaryAddress = (this.userObject.deliveryAddressesFromServer as AddressPosition[]).find(adr => adr.isPrimary == true);
              this.SET_USER_USED_ADDRESS(primaryAddress)
            });
          });      
      }
    },

  login() {

      this.loginAttempts++;
      if(this.isReceptchaActive == true && this.isRecaptchaVerified == false)
        return;

      if (this.credentials.password.length > 7 && this.credentials.tempPhoneNumber.length > 0) {
        this.loading = true;
        this.fullLoading = true;

        var phoneNumberToUse = this.credentials.phoneNumber.replace('+', '');
        

        _requestHelper({          
          method: "post",
          url: Apis.loginSubUrl,
          data: {
            phoneNumber: phoneNumberToUse,
            password: this.credentials.password,
            permissivePhoneConfirmation: true
          },
          loaderKey: "authenticationLoading",

          successCallback: async (user: any) => {
            this.resetRecaptcha = true;
            this.resetRecaptcha = false;
            this.isRecaptchaVerified = false;

            if(typeof user != "undefined") {


              // if phone number not confirmed => go to registration with prefilled data
              if(user.phoneNumberConfirmed == false) {
                this.goToRegister(user);
              }
              
              // login normally
              else {
                this.$store.commit("auth/SET_USER", user);
                
                // if user doesn't have any local address =>                 
                if(typeof this.userObject.usedAddress == "undefined" || this.userObject.usedAddress == null || typeof this.userObject.usedAddress.city == "undefined") {
              
                  // get user's addresses
                  this.getUserAddresses().then(async () => { 
                    var primaryAddress = this.userObject.deliveryAddressesFromServer.find(adr => adr.isPrimary == true);

                    if(primaryAddress != null) {
                      this.SET_USER_USED_ADDRESS(primaryAddress)                      
                    }                    
                  })
                }

                // if user has a local address 
                // update user addresses and retrieve the updated list
                else {
                  // get list of addresses from server
                  await this.getUserAddresses()
                  .then(async () => {
                    var adrFromServer = this.listOfUserAddresses as AddressPosition[];     
                    var primaryAddress = adrFromServer != null ? adrFromServer.find(adr => adr.isPrimary == true) : {} as AddressPosition;


                    // if there is any primary address -> update address by the new local address
                    this.createOrUpdateAddress(primaryAddress!=null ? primaryAddress.id : 0); 
                    
                    this.$emit("closeDialog");
                  });
                }

                this.credentials.tempPhoneNumber = "";
                this.credentials.phoneNumber = "";
                this.credentials.password = "";
                
                this.loginAttempts = 0;
                
                this.$emit("onAuthenticationDone");
              }
            }

            else {
              this.resetRecaptcha = true;
              this.isRecaptchaVerified = false;
            }

            

            this.fullLoading = false;

          }
        })
        .catch((message) => {
          this.$store.commit("ui/snackbar", {show: true, message: message, error: true});
          this.fullLoading = false;
          this.resetRecaptcha = true;
          this.resetRecaptcha = false;
          this.isRecaptchaVerified = false;
        })
        .finally(() => {          
          this.loading = false;
          this.resetRecaptcha = true;
          this.resetRecaptcha = false;
          this.isRecaptchaVerified = false;
        })
      }

      else {
        this.isRecaptchaVerified = false;

        if(this.isReceptchaActive == true)
          this.resetRecaptcha = true;

        if(this.credentials.tempPhoneNumber.trim().length == 0) {
          this.$store.commit("ui/snackbar", {
            error: true,
            message: this.appDataContent.authLoginEmptyMobileNbreTxtBnr,
            show: true
          })
        }

        else if(this.credentials.password.length == 0) {
          this.$store.commit("ui/snackbar", {
            error: true,
            message: this.appDataContent.authLoginEmptyPwdTxtBnr,
            show: true
          })
        }

        else if(this.credentials.password.length < 8) {
          this.$store.commit("ui/snackbar", {
            error: true,
            message: this.appDataContent.authForgotPwdLenghPwdIncorrecrTxtBnr,
            show: true
          })
        }
      }
    },

    // ------------------------------- //
    // address related //
    // ------------------------------- //
    async setDefaultAddressFromPartnerSettings() {
      var coords = {
        lat: (this.partnerSettings as PartnerSettings).defaultUserLocationCoordinates.latitude,
        lng: (this.partnerSettings as PartnerSettings).defaultUserLocationCoordinates.longitude,
      };

      await this.getAddress({
          latitude: coords.lat,
          longitude: coords.lng
        }).then(() => {
          this.ADD_LOCAL_ADDRESS({
            addressInfo: this.address, 
            addressPosition: { 
              lat: coords.lat,
              lng: coords.lng
            }
          });
        });    
    },

    

    goToRegister(data?: any) {
      
      if(data){
        data.localPhoneNumber = this.credentials.tempPhoneNumber;
        data.countryCode = this.credentials.countryCode;

        this.$emit("setRegisterPage", data);
      }

      else 
        this.$emit("setRegisterPage");
    },

    goToForgotPassComponent() {
      this.$emit("setForgotPassPage");
    }

  },

  watch: {
    isDialogOpen(value: boolean) {
      if(value == false) {
        this.credentials.tempPhoneNumber = "";
        this.credentials.phoneNumber = "";
        this.credentials.password = "";
        this.isRecaptchaVerified = false;
      }
      

      else {
        this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.loginPage);
      }
    }
  },

  mounted() {
      this.credentials.tempPhoneNumber = "";
        this.credentials.phoneNumber = "";
        this.credentials.password = "";
        this.isRecaptchaVerified = false;

    // firebase analytics
    this.LOG_FIREBASE_SCREEN(FirebaseAnalyticsScreensNames.loginPage);
  }
});
